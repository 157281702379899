import * as React from 'react';
import { PublicCosting } from '../../../templates/PublicCosting/PublicCosting';
import { Router } from '@reach/router';
import { RouterComponent } from '../../../components/RouterComponent/RouterComponent';
import { UnauthenticatedTemplate, AuthenticatedTemplate } from '@azure/msal-react';
// import * as styles from './costing.module.scss'

export interface ICostingProps{

}

export default function Costing(){
    return(
        <Router basepath="/secure/response" component={ ({children}) => <RouterComponent children={children} /> }>
            <SecureCostingWrapper path=":responseId" />
        </Router>
    )
}


const SecureCostingWrapper = (props: any) => {
    return (
        <>
            <AuthenticatedTemplate>
                <PublicCosting path=":responseId" isSecure={ true } {...props} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <span>Please login to access this page.</span>
            </UnauthenticatedTemplate>
        </>
    )
}